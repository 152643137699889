import React from 'react'
import { Container } from 'semantic-ui-react'


export default function HomePage(){
    return (
        <div className='mt-5'>
        <h1>HomePage</h1>
        </div>
    )
}