import React from 'react'
import CashBackPickupTable from './CashBackPickupTable'
import CashBackPlanetsTable from './CashBackPlanetsTable'
import DevloperMode from './DeveloperMode'



export default function CashBack(){
    return (
        <div className='mt-5'>
        <h1>Hello Worlds</h1>
        {/* <DevloperMode/> */}
            <CashBackPlanetsTable/>
            <CashBackPickupTable/>

nice


        </div>
    )
}