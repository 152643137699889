import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Container, Dropdown, Menu } from 'semantic-ui-react';

// interface Props {
//     openForm: () => void;
// }

// export default function NavBar({openForm}: Props) {

export default function NavBar() {
    return (
        <Menu className="bg-gradient-to-r from-blue-500 to-purple-400 p-3" inverted fixed='top'>
            <Container>
                <Menu.Item as={NavLink} to='/' exact header>HomePage</Menu.Item>
                <Menu.Item as={NavLink} to='Cashback'> Cashback</Menu.Item>

                {/* <Dropdown>sdfsdf</Dropdown> */}
                {/* <Menu.Item as={NavLink} to='/activities' name='Activities' /> */}
                {/* <Menu.Item header>


                <Button>Default</Button>
                <Button>O mnie</Button>
                Website in development mode. New content coming soon  Version Prebuild 0.0.0.0
                </Menu.Item> */}

            </Container>
        </Menu>
    )
}