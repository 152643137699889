import * as React from 'react'
import ReactDOM from 'react-dom'
// import oz from '../assets/resource-ozy.png'
import '../index.css'

import {
  createTable,
  getCoreRowModel,
  useTableInstance,
} from '@tanstack/react-table'

import { deepEqual } from 'assert'
import axios from 'axios'
import { CashBackPlanetDto } from '../models/CashBackPlanetDto'

// type CashBackPlanetDto = {
//     planet: string;
//     name: string;
//     location: string
//     depth1: string
//     depth2: string
//     depth3: string
//     isReady: string
//     cashBack: string
//     cashBackFlat: string
//           };

const table = createTable().setRowType<CashBackPlanetDto>()

const defaultColumns = [
      table.createDataColumn(row => row.planet, {
        id: 'planet',
        cell: info => info.getValue(),
        header: () => <span>Planet</span>,
        footer: props => props.column.id,
      }),
      table.createDataColumn(row => row.name, {
        id: 'name',
        cell: info => info.getValue(),
        header: () => <span>Name</span>,
        footer: props => props.column.id,
      }),
      table.createDataColumn(row => row.location, {
        id: 'location',
        cell: info => info.getValue(),
        header: () => <span>Location</span>,
        footer: props => props.column.id,
      }),
      table.createDataColumn(row => row.depth1, {
        id: 'depth1',
        cell: info => info.getValue(),
        header: () => <span>Depth1</span>,
        footer: props => props.column.id,
      }),
      table.createDataColumn(row => row.depth2, {
        id: 'depth2',
        cell: info => info.getValue(),
        header: () => <span>Depth2</span>,
        footer: props => props.column.id,
      }),
      table.createDataColumn(row => row.depth3, {
        id: 'depth3',
        cell: info => info.getValue(),
        header: () => <span>Depth3</span>,
        footer: props => props.column.id,
      }),
      table.createDataColumn(row => row.plotLeft, {
        id: 'plotLeft',
        cell: info => info.getValue(),
        header: () => <span>PlotLeft</span>,
        footer: props => props.column.id,
        
      }),
      table.createDataColumn(row => row.isReady, {
        id: 'isReady',
        cell: info => info.getValue(),
        header: () => <span>IsReady</span>,
        footer: props => props.column.id,
        
      }),
      table.createDataColumn(row => row.cashBack, {
        id: 'cashBack',
        cell: info => info.getValue(),
        header: () => <span>CashBack</span>,
        footer: props => props.column.id,
      }),
      table.createDataColumn(row => row.cashBackFlat, {
        id: 'cashBackFlat',
        cell: info => info.getValue(),
        header: () => <span>CashBackFlat</span>,
        footer: props => props.column.id,
      })
]

interface Props{
  planetData: CashBackPlanetDto[]
}


function CashBackPlanetsTable(
  // {planetData} : Props
  ){

  // const [data, setData] = React.useState(() => [...planetData])
  const [data, setData] = React.useState(() => [])

  const [loading, setLoading] = React.useState(false);
  const [copper, setCopper] = React.useState(0);

  const [columns] = React.useState<typeof defaultColumns>(() => [
    ...defaultColumns,
  ])


  React.useEffect(()=>{
    console.log('data zmiemniona')
    rerender();
    console.log(data)
    },[data])
    
    let baseURL: string = process.env.REACT_APP_PLANETS!;
    // axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    
    const getData = async() =>{
       axios.get(baseURL).then( response => {
        setData(response.data)
      })
      setLoading(false);
    }
    
    const rerender = React.useReducer(() => ({}), {})[1]
      React.useEffect(()=>{
        setLoading(true);
        getData();
        },[])
    




//   React.useEffect(()=>
//   {
//     let x: CashBackPlanetDto[] = planetData;
//     setData(x)
//   },[planetData]
// )
  
  const instance = useTableInstance(table, {
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return ( 
    <div className="flex items-center justify-center align-middle text-center mt-10">
        <div>
        {/* <div className='inline-block align-middle text-center m-0'> */}
        <div className="text-3xl text-red-600 font-teko">List of active cashback planets</div>

        <table className="content-table">
        <thead className="bg-blue-400">
          {instance.getHeaderGroups().map(headerGroup => (
            <tr  key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th 
                className='px-4 py-2 text-black-600'
                
                key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : header.renderHeader()}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {instance.getRowModel().rows.map(row => (
            <tr  className='px-4 py-2 text-yellow-600 border-4  odd:bg-white even:bg-slate-100 font-spiegel' key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td  className='px-4 py-2 text-yellow-600 ' key={cell.id}>{cell.renderCell()}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="h-4" />
    </div>
         </div>
  )
}


export default CashBackPlanetsTable;