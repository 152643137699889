import * as React from 'react'
import ReactDOM from 'react-dom'
// import oz from '../assets/resource-ozy.png'
import '../index.css'

import {
  createTable,
  getCoreRowModel,
  useTableInstance
} from '@tanstack/react-table'

import { deepEqual } from 'assert'
import { CashBackPickupDto } from '../models/CashBackPickupDto'
import axios from 'axios'


const table = createTable().setRowType<CashBackPickupDto>()

const defaultColumns = [
      table.createDataColumn(row => row.wallet, {
        id: 'wallet',
        cell: info => info.getValue(),
        header: () => <span>Wallet</span>,
        footer: props => props.column.id,
      }),
      table.createDataColumn(row => row.digged, {
        id: 'Digged',
        cell: info => info.getValue(),
        header: () => <span>Digged</span>,
        footer: props => props.column.id,
      }),
      table.createDataColumn(row => row.cashBack, {
        id: 'CashBack',
        cell: info => info.getValue(),
        header: () => <span>CashBack</span>,
        footer: props => props.column.id,
      })

]

interface Props{
  pickupData: CashBackPickupDto[]
}


// const [pickupData, setWeather] = useState([]);


// function CashBackPickupTable({pickupData} : Props) {

function CashBackPickupTable() {

  const [data, setData] = React.useState<CashBackPickupDto[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [copper, setCopper] = React.useState(0);

React.useEffect(()=>{
console.log('data zmiemniona')
rerender();
console.log(data)
},[data])

let baseURL: string = process.env.REACT_APP_PICKUP!;
// axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const getData = async() =>{
   axios.get(baseURL).then( response => {
    setData(response.data)
  })
  setLoading(false);
}

const rerender = React.useReducer(() => ({}), {})[1]
  React.useEffect(()=>{
    setLoading(true);
    getData();
    },[])


  let [columns] = React.useState<typeof defaultColumns>(() => [
    ...defaultColumns,
  ])

  let instance = useTableInstance(table, {
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className="flex items-center justify-center align-middle text-center mt-10">
        <div>
        {/* <div className='inline-block align-middle text-center m-0'> */}
        <div className="text-3xl text-red-600 font-teko">List of active cashback planets</div>
        <table className="content-table">
        <thead className="bg-blue-400">
          {instance.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th className='px-4 py-2 text-black-600' key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : header.renderHeader()}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {instance.getRowModel().rows.map(row => (
            <tr  className='px-4 py-2 text-yellow-600 border-4  odd:bg-white even:bg-slate-100 font-spiegel' key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td  className='px-4 py-2 text-yellow-600 'key={cell.id}>{cell.renderCell()}</td>
              ))}
            </tr>
          ))}
        </tbody>
        {/* <tfoot>
          {instance.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : header.renderFooter()}
                </th>
              ))}
            </tr>
          ))}
        </tfoot> */}
      </table>
      <div className="h-4" />
      {/* <button onClick={() => rerender()} className="border p-2">
        Rerender
      </button> */}
    </div>
    </div>

  )
}


export default CashBackPickupTable;