import './App.css';
import NavBar from './layout/NavBar';
import CashBackPlanets from './features/CashBackPlanets';
import CashBackPickup from './features/CashBackPickup';
// import DisplayPlanetsTable from './features/DisplayPlanetsTable';
import DevloperMode from './features/DeveloperMode';
import CashBackPickupTable from './features/CashBackPickupTable';
import CashBackPlanetsTable from './features/CashBackPlanetsTable';
import { Route } from 'react-router-dom';
import HomePage from './features/HomePage';
import CashBack from './features/CashBack';
import Header from './layout/Header';
import { useEffect } from 'react';

function App() {

  useEffect(() => { 
  document.body.style.backgroundImage = "url('/assets/space2.jpg')" 
  document.body.style.backgroundRepeat = "no-repeat";
  document.body.style.backgroundSize  = "cover";
  document.body.style.backgroundPosition = "center center";
}, []) 

  const minerResources: string[] = ["copper", "silver", "iron"]

  return (
    // <div className="container mt-12 h-14 bg-gradient-to-r from-blue-500 to-purple-400 p-3">
    <div>
       <NavBar />
      <br/>
      <div className='mt-10 bg-black'>
      {/* <DevloperMode /> */}
      </div>
        <Route exact path='/' component={HomePage}/>
        <Route path='/cashback' component={CashBack}/>
        {/* <div className="bg-hero"></div> */}
        <br/>
        {/* <CashBackPlanetsTable/>
        <br/>
        <CashBackPickupTable/> */}
        <br/>
         <p>
         </p>
     <div className="text-green-600">Version Prebuild 0.0.0.0</div>
     </div>
  );

  
}

export default App;
